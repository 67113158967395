<template>
  <nav class="navbar">
    <SwoopLogo height="36px"/>
    <div class="contact"><span>Call us: <a href="tel:0203 318 6489">0203 318 6489</a></span> <span>Email us: <a href="mailto:grants@swoopfunding.com">grants@swoopfunding.com</a></span></div>
  </nav>
</template>

<script>
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'Navbar',
  components: { SwoopLogo }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

nav.navbar {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  img.partner-logo {
    width: 120px;

    @media only screen and (max-width: $breakpoint-sm-max) {
      width: 100px;
    }
  }
  .contact {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: right;

  a {
      text-decoration: none;
    }

    @media only screen and (max-width: $breakpoint-sm-max) {
      text-align: left;
    }
  }
}
</style>
