<template>
  <div class="page-wrapper">
    <Navbar />
    <div class="container">
      <Form
        title="Grant requirements"
        subtitle="It takes less than 1 minute to answer these questions and see grants relevant to your business."
        info="Please note: The tool is only available for UK domiciled businesses."
        :loading="loading"
        :form="form"
        @submit="submit"
      />
      <Footer :natwestFooter="true" />
    </div>
  </div>
</template>

<script>
import Form from '@product/features/products/components/Form'
import Navbar from '../components/Navbar'
import grantForm from './grant.form'
import mapsFields from '@/mixins/mapsFields'
import Footer from '@/components/Footer'
import checkGrantFinderRedirects from '../mixins/checkGrantFinderRedirects'
import { getSectorList, getCountryList, getFundingPurposeList } from '@/api/pageMetadata/'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { toastTopCenterOptions } from '@/config/vue-toast'
import { amplitudeEvents } from '@/utils/constants'
import { getPostcode } from '@/api/postcodes'

export default {
  components: { Form, Navbar, Footer },
  mixins: [mapsFields, checkGrantFinderRedirects],
  data() {
    return {
      loading: true,
      form: grantForm,
      companyStructuresList: [
        { value: '1', label: 'Limited Company' },
        { value: '2', label: 'LLP' },
        { value: '3', label: 'Sole Trader' },
        { value: '6', label: 'Partnership' }
      ]
    }
  },
  created() {
    const localStorageData = localStorage.getItem('formData')

    if (localStorageData) {
      const formData = JSON.parse(localStorageData)

      this.attachToField('firstCommercialSale', 'value', formData.firstCommercialSale)
    }
    this.fetchFormData()
    this.attachFormWatchers()
  },
  mounted() {
    this.trackPageView()
  },
  methods: {
    fetchFormData() {
      return Promise.all([
        getSectorList().then(({ data }) => this.mapFields(data, 'sectors')),
        getCountryList().then(({ data }) => {
          data = data.sort(item => {
            if (item.label.toLowerCase() === this.$config.whitelabel.country.defaultCountry.toLocaleLowerCase()) {
              return -1
            }

            return 1
          })
          this.mapFields(data, 'registeredAddress.country')
          this.mapFields(data, 'tradingAddress.country')
        }),
        getFundingPurposeList().then(({ data }) => this.mapFields(data, 'fundingPurposes')),
        this.mapFields(this.companyStructuresList, 'companyStructures')
      ]).then(() => {
        this.loading = false
      })
    },
    attachFormWatchers() {
      const mapFields = ['line', 'city', 'country', 'postcode']
      this.watchField('company', company => {
        if (company?.registeredAddress) {
          mapFields.forEach(field => {
            if (field === 'country') {
              this.attachToFieldValue(`registeredAddress.${field}`, company.registeredAddress.countryId)
              this.attachToFieldValue(`tradingAddress.${field}`, company.registeredAddress.countryId)
            } else {
              this.attachToFieldValue(`registeredAddress.${field}`, company.registeredAddress[`${field}`])
              this.attachToFieldValue(`tradingAddress.${field}`, company.registeredAddress[`${field}`])
            }
          })
        }
      })
    },
    trackMainCTAEvent(data) {
      getPostcode(data.registeredAddress.postcode)
        .then(({ data: { result } }) => {
          const tradingYear = new Date(data.firstCommercialSale).getFullYear()

          this.$ma.trackEvent(amplitudeEvents.natWestSMEGrants.GRANTS_FORM_SUBMITTED)

          this.$ma.setUserProperties({
            'Company Structure': this.getSelectedOptionLabel('companyStructures'),
            Amount: data.totalFundingRequired,
            Activities: this.getSelectedOptionLabels('fundingPurposes'),
            Turnover: data.turnoverLastYear,
            'Trading Year': tradingYear,
            Sector: this.getSelectedOptionLabels('sectors'),
            District: result ? result.admin_district : ''
          })
          this.loading = false
          this.$router.push({ name: 'natwest-grants' })
        })
        .catch(e => {
          this.loading = false
          this.$store.dispatch(notificationRoutine.TRIGGER, {
            title: 'API Error',
            options: { ...toastTopCenterOptions, className: 'toasted-error' },
            text: `${e.response?.data?.error || 'Something went wrong'}`
          })
        })
    },
    trackPageView() {
      this.$ma.trackEvent(amplitudeEvents.natWestSMEGrants.LANDING_PAGE_VIEW_GRANTS_FORM)
    },
    submit(data) {
      this.loading = true
      if (data.isSoleTrader) {
        data.company.sourceType = 'SoleTrader'
      }
      this.saveLocalStorage(data)
      this.trackMainCTAEvent(data)
    },
    saveLocalStorage(data) {
      localStorage.setItem('formData', JSON.stringify(data))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  max-width: 900px;
  padding-bottom: 1rem;
  padding-top: 30px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 1rem;
  }
}
</style>
