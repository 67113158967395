export default [
  {
    type: 'company-search',
    name: 'company',
    label: 'What is the name of your business?',
    mode: 'aggressive',
    value: '',
    rules: 'required',
    tooltip: 'If you cannot find your business in the drop down please add your business name manually.',
    disabledTextAutoComplete: true
  },
  {
    type: 'select',
    name: 'companyStructures',
    label: 'Business type structure',
    options: [],
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'registeredAddress.line',
    label: 'What is your registered address?',
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'registeredAddress.city',
    label: 'City / Town',
    value: '',
    rules: 'required'
  },
  {
    type: 'select',
    name: 'registeredAddress.country',
    label: 'Country',
    rules: 'required',
    options: [],
    value: ''
  },
  {
    type: 'input',
    name: 'registeredAddress.postcode',
    label: 'Postcode',
    rules: 'required',
    value: ''
  },
  {
    type: 'radio',
    name: 'tradingAddressIsDifferent',
    label: 'Does your business use a different trading address?',
    value: '',
    rules: 'required',
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ],
    tooltip: 'Some grants are specific to your companies location, so we need this data to match you to appropriate grants'
  },
  {
    type: 'input',
    name: 'tradingAddress.line',
    label: 'What is your trading address?',
    value: '',
    rules: 'required',
    dependsOn: [
      {
        field: 'tradingAddressIsDifferent',
        value: true
      }
    ]
  },
  {
    type: 'input',
    name: 'tradingAddress.city',
    label: 'City / Town',
    value: '',
    rules: 'required',
    dependsOn: [
      {
        field: 'tradingAddressIsDifferent',
        value: true
      }
    ]
  },
  {
    type: 'select',
    name: 'tradingAddress.country',
    label: 'Country',
    rules: 'required',
    options: [],
    value: '',
    dependsOn: [
      {
        field: 'tradingAddressIsDifferent',
        value: true
      }
    ]
  },
  {
    type: 'input',
    name: 'tradingAddress.postcode',
    label: 'Postcode',
    rules: 'required',
    value: '',
    dependsOn: [
      {
        field: 'tradingAddressIsDifferent',
        value: true
      }
    ]
  },
  {
    type: 'input',
    dataType: 'currency',
    label: 'How much funding do you require?',
    name: 'totalFundingRequired',
    value: '',
    icon: 'currency',
    iconPosition: 'left',
    rules: 'required',
    tooltip: 'Some grants only offer a certain amount of funding, we need to know your requirement so that we can match you to the best suited options.'
  },
  {
    type: 'multiselect',
    label: 'What activities would you like to fund?',
    value: [],
    name: 'fundingPurposes',
    rules: 'required',
    options: [],
    tooltip:
      'Different grants are available depending on what you plan to spend the money on. For example some grants are for spending on developing new products or processes, others are to buy new machinery.'
  },
  {
    type: 'input',
    dataType: 'currency',
    label: 'What was your turnover in your last financial year?',
    name: 'turnoverLastYear',
    value: '',
    icon: 'currency',
    iconPosition: 'left',
    rules: 'required',
    tooltip: 'Knowing your previous year turnover helps us to match you to grants which require the business to be trading.'
  },
  {
    type: 'date',
    dataType: 'month',
    name: 'firstCommercialSale',
    label: 'When did you start trading? (First commercial Sale)',
    value: new Date().getTime() / 1000,
    returnType: 'mm/yyyy',
    tooltip: 'Some Grants require the business to have been trading for a certain amount of time.'
  },
  {
    type: 'multiselect',
    label: 'Which sector(s) do you operate in?',
    value: [],
    name: 'sectors',
    rules: 'required',
    options: [],
    tooltip: 'This will help us to match you to sector specific grant funds that are currently running.'
  }
]
